import * as React from 'react';
import {observer} from 'mobx-react';
import {sign_in_form} from '../forms/sign_in';
import {Input} from '../../../components/fields/input';
import {Panel} from '../../../components/section_panel'

@observer
class Sign_in_container extends React.Component {

  render() {
    return (
        <div className="sign_in_wrap">
          <Panel title={'Вхід'}/>
          <form className="form_wrap">
            <Input field={sign_in_form.$('login')}/>
            <Input field={sign_in_form.$('password')}/>
            <button
                className="sign_in_button"
                onClick={sign_in_form.onSubmit}
            >
              Увійти
            </button>
          </form>
        </div>
    )
  }

}

export {Sign_in_container}
