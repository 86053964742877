import * as React from 'react';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';
import {PAYMENT_TYPES_LITERALS} from '../../../constants';

@observer
class Record extends React.Component {

  static propTypes = {
    record: PropTypes.object
  };

  render() {

    const {
      record: {
        type,
        amount,
        created_by: {
          first_name,
          last_name,
          avatar,
          email,
          telephone
        }
      }
    } = this.props;

    const full_name = `${first_name} ${last_name}`;

    return (
        <div className="record_wrap">
          <div className="user_info">
            <div className="avatar" style={{
              backgroundImage: `url("${avatar?.source_url}")`
            }}/>
            <div className="full_name" title={full_name}>{full_name}</div>
          </div>
          <div className="literal_info" title={telephone.telephone}>{telephone.telephone}</div>
          <div className="literal_info" title={email.email}>{email.email}</div>
          <div className="literal_info centered">{PAYMENT_TYPES_LITERALS[type]}</div>
          <div className="value right">{amount} $</div>
        </div>
    )

  }
}

export {
  Record
};
