import * as React from 'react';
import {observer} from 'mobx-react';

import {Withdrawal_service} from '../../../services';
import {Panel} from '../../../components/section_panel'
import { LoaderWrapper } from '../../../components/loader'

import {Withdrawal_records_store} from '../../../stores';
import {Record} from '../components';
import {LOADER_TYPES} from "../../../constants";

@observer
class Withdrawal_records extends React.Component {

  componentDidMount() {
    Withdrawal_service.get_withdraw_records()
  }

  renderList = () => Withdrawal_records_store.records.map(record => (
    <Record key={`record:${record.id}`} record={record}/>
  ));

  render() {
    return (
        <div className="withdraw_tab">
          <Panel title={'Запити на вивід'}/>
          <LoaderWrapper literal={LOADER_TYPES.GET_WITHDRAW_RECORDS}>
            <div className="list-wrap">
              {this.renderList()}
            </div>
          </LoaderWrapper>
        </div>
    )
  }

}

export {Withdrawal_records}
