import {
  User_service
} from '../../../services';
import {history} from '../../../helpers/history';
import {ROUTES} from "../../../constants";

export class Sign_in_service {
  static async on_sign_in_submit(data) {
    const success = await User_service.authenticate_user(data);

    if (success) {
      history.push(ROUTES.WITHDRAWAL)
    }
  }
}
