import * as React from 'react';
import {Router} from 'react-router-dom';

import {Router as UserRoutes} from "./router";
import {history} from "./helpers/history";
import {User_service} from "./services";
import {Toast} from './components/toast'
import {LoaderWrapper} from './components/loader'
import {LOADER_TYPES} from "./constants";

class App extends React.Component {
  componentDidMount() {
    User_service.load_profile();
  }

  render() {
    return (
        <React.Fragment>
          <Toast/>
          <Router history={history}>
            <main className='main-layout'>
              <LoaderWrapper literal={LOADER_TYPES.APP_INITIALIZE} fullScreen>
                <UserRoutes/>
              </LoaderWrapper>
            </main>
          </Router>
        </React.Fragment>
    )
  }
}

export {App}
