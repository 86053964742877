import moment from 'moment'

export const PERIOD_FILTERS_CONFIG = [
  {
    title: 'День',
    get_value: () => moment().utc().subtract(1, 'days').toDate()
  },
  {
    title: 'Тиждень',
    get_value: () => moment().utc().subtract(7, 'days').toDate()
  },
  {
    title: 'Місяць',
    get_value: () => moment().utc().subtract(1, 'months').toDate()
  },
  {
    title: 'Рік',
    get_value: () => moment().utc().subtract(1, 'years').toDate()
  }
];
