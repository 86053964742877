import * as React from 'react';
import {observer} from 'mobx-react';

import {Leaders_service} from '../../../services';

import {Leaders_store, Franchise_store} from '../../../stores';
import {Record} from '../components';
import {LOADER_TYPES, ROUTES} from "../../../constants";
import {Show} from "../../../components/condition";
import {history} from '../../../helpers/history';
import {toast} from "react-toastify";

import {Franchise_records} from '../../franchise'

@observer
class Leader_info_records extends React.Component {

  constructor(props) {
    super(props);
    this.leader_id = history.location.pathname.split('/').reverse()[0];
  }

  componentDidMount() {
    this.fetch_leader_info({id: this.leader_id, reset: true});
  }

  componentWillUnmount() {
    Leaders_store.clear();
    Franchise_store.clear();
  }

  async fetch_leader_info(payload) {
    const success = await Leaders_service.get_leaders_records(payload);

    if (!success) {
      toast.error('Не вдалося знайти лідера');
      history.push(ROUTES.LEADERS);
    }
  }

  render() {

    const leader_record = Leaders_store.records[0];
    return (
        <React.Fragment>
          <div className="leaders_tab" style={{marginBottom: 50}}>
            <div className="list-wrap">
              <Show condition={!!leader_record}>
                <Record  ignoring_pointer_events key={`record:${leader_record?.id}`} record={leader_record}/>
              </Show>
            </div>
          </div>
          <Franchise_records franchise_leader_id={this.leader_id}/>
        </React.Fragment>
    )
  }

}

export {Leader_info_records}
