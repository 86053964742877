import {
  observable,
  action
} from 'mobx';

export const Bills_store = new class {
  @observable records = [];
  @observable list_end_reached = true;
  @observable total = 0;

  @action set_records(records = []) {
    this.records = records
  }

  @action set_total(total = 0) {
    this.total = total;
  }

  @action clear() {
    this.records = [];
    this.total = 0;
  }


  @action push_records(records = []) {
    this.records = this.records.concat(records)
  }

  @action add_record(record = {}) {
    this.records.unshift(record);
  }

  @action set_list_end_reached_status(value) {
    this.list_end_reached = value
  }
}();
