import * as React from 'react';

export const Trash = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <style>{'.trash-a{fill:#c5c5c5;}.trash-b{fill:none;}'}</style>
      </defs>
      <path className="trash-a" d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM19,4H15.5l-1-1h-5l-1,1H5V6H19Z"/>
      <path className="trash-b" d="M0,0H24V24H0Z"/>
    </svg>
);
