import * as React from 'react';
import * as PropTypes from 'prop-types'

export class Panel extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.element
  };

  render() {

    const {title, children} = this.props;

    return (
        <div className="panel_wrap">
          <div className="section_title">{title}</div>
          {children}
        </div>
    )
  }
}
