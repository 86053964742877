import * as React from 'react';
import {Switch} from 'react-router-dom';
import {
  ROUTES
} from '../constants'
import {Private} from './Private';
import {NonAuthRoute} from '../components/route';

import {Sign_in_container} from '../stories/sign_in';

class Router extends React.Component {
  render() {
    return (
        <Switch>
          <NonAuthRoute exact path={ROUTES.SIGN_IN} component={Sign_in_container}/>
          <Private/>
        </Switch>
    )
  }
}

export {Router};
