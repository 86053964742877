import * as React from 'react';
import * as PropTypes from 'prop-types';

import { Trash } from '../../../assets/icons';
import { Show } from '../../../components/condition';

class Attachments_panel extends React.Component {
  static defaultProps = {
    delete_attachment: () => () => {}
  };

  static propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.object),
    should_delete: PropTypes.bool,
    delete_attachment: PropTypes.func
  };

  render_list = attachments => attachments.map(attachment => (
      <div key={attachment.id} className="attachment">
        <div className="attachment_item" style={{
          backgroundImage: `url("${attachment.source_url}")`
        }}/>
        <Show condition={this.props.should_delete}>
          <div className="delete_area">
            <div className="delete_section" onClick={this.props.delete_attachment(attachment.id)}>
              {Trash}
            </div>
          </div>
        </Show>
      </div>
  ));

  render() {

    const {
      attachments = [],
    } = this.props;

    return (
        <div className="attachments_wrap">
          {this.render_list(attachments)}
        </div>
    )
  }
}

export {
  Attachments_panel
};
