import * as React from 'react';

class Header extends React.PureComponent {

  render() {

    return (
        <div className="record_wrap head">
          <div/>
          <div className="literal_info">Телефон</div>
          <div className="literal_info">Email</div>
          <div className="literal_info centered" title='Призначення'>Призначення</div>
          <div className="literal_info right" title='Сума'>Сума</div>
        </div>
    )
  }
}

export {
  Header
};
