import {
  observable,
  action
} from 'mobx';

export const Notification_records_store = new class {
  @observable records = [];
  @observable list_end_reached = true;

  @action set_records(records = []) {
    this.records = records
  }

  @action clear() {
    this.records = []
  }


  @action push_records(records = []) {
    this.records = this.records.concat(records)
  }

  @action add_record(record = {}) {
    this.records.unshift(record);
  }

  @action remove_record(target_id = null) {
    this.set_records(
        this.records.filter(({id} = {}) => id !== target_id)
    )
  }

  @action set_list_end_reached_status(value){
    this.list_end_reached = value
  }
}();
