import Form from 'mobx-react-form';
import vjf from 'mobx-react-form/lib/validators/VJF';
import {is_required, is_valida_telephone} from '../../../helpers/validators';
import {Sign_in_service} from '../services'

const plugins = {
  vjf: vjf()
};

const fields = [
  {
    name: 'login',
    placeholder: '+(380)*********',
    validators: [is_required, is_valida_telephone]
  },
  {
    name: 'password',
    placeholder: 'Пароль',
    type: 'password',
    validators: [is_required]
  }
];

const hooks = {
  onSuccess(form) {
    return Sign_in_service.on_sign_in_submit(form.values())
  },
};

export const sign_in_form = new Form({fields}, {plugins, hooks});
