import * as React from 'react';

export const Bills_tab_icon = (

    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="26"
         viewBox="0 0 24 26">
      <defs>
        <style>{'.bills-a{fill:none;}.bills-b{fill:#437c90;}.bills-c{filter:url(#a);}'}</style>
        <filter id="bills-a" x="3.32" y="2" width="16.18" height="24" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1" result="bills-b"/>
          <feFlood flood-color="#001d27" flood-opacity="0.137"/>
          <feComposite operator="in" in2="bills-b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <path className="bills-a" d="M0,0H24V24H0Z"/>
      <g className="bills-c" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path className="bills-b"
              d="M11.8,10.9c-2.27-.59-3-1.2-3-2.15,0-1.09,1.01-1.85,2.7-1.85,1.78,0,2.44.85,2.5,2.1h2.21A3.986,3.986,0,0,0,13,5.19V3H10V5.16c-1.94.42-3.5,1.68-3.5,3.61,0,2.31,1.91,3.46,4.7,4.13,2.5.6,3,1.48,3,2.41,0,.69-.49,1.79-2.7,1.79-2.06,0-2.87-.92-2.98-2.1H6.32c.12,2.19,1.76,3.42,3.68,3.83V21h3V18.85c1.95-.37,3.5-1.5,3.5-3.55C16.5,12.46,14.07,11.49,11.8,10.9Z"/>
      </g>
    </svg>
);
