import * as React from 'react';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';

import {Loader} from './Loader';
import {Progress_store} from '../../stores';

@observer
class LoaderWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    literal: PropTypes.string,
    fullScreen: PropTypes.bool
  };

  render() {
    const {literal, children, fullScreen} = this.props;
    return Progress_store.is_loading(literal) ? <Loader is_active fullScreen={fullScreen}/> : children;
  }
}

export {
  LoaderWrapper
}
