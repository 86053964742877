import * as React from 'react';
import {observer} from 'mobx-react';

import {Notifications_service} from '../../../services';
import {Panel} from '../../../components/section_panel'
import {LoaderWrapper} from '../../../components/loader'

import {Notification_records_store} from '../../../stores';
import {Record} from '../components';
import {LOADER_TYPES, ROUTES} from "../../../constants";
import {history} from '../../../helpers/history';

import {Show} from '../../../components/condition'

import { PlusIcon } from '../../../assets/icons';

@observer
class Notifications_list extends React.Component {

  static records_per_page_count = 20;

  constructor(props, context) {
    super(props, context);
    this.current_offset = 0;
  }

  componentDidMount() {
    this.load_page({offset: this.current_offset, limit: Notifications_list.records_per_page_count, reset: true});
  }

  componentWillUnmount() {
    Notification_records_store.clear()
  }

  async load_page(payload) {
    this.current_offset = payload.offset + Notifications_list.records_per_page_count;
    return Notifications_service.get_notifications_list(payload);
  }

  renderList = () => Notification_records_store.records.map(record => (
      <Record key={`notification:${record.id}`} id={record.id} record={record}/>
  ));

  render() {
    return (
        <div className="notifications_tab">
          <Panel title={'Сповіщення'}>
            <div className="add_button" onClick={() => history.push(ROUTES.CREATE_NOTIFICATION)}>
              {PlusIcon}
            </div>
          </Panel>
          <LoaderWrapper literal={LOADER_TYPES.GET_NOTIFICATIONS_LIST}>
            <div className="list-wrap">
              {this.renderList()}
            </div>
            <Show condition={!Notification_records_store.list_end_reached}>
              <div className="load_more_button" onClick={() => this.load_page({
                offset: this.current_offset,
                limit: Notifications_list.records_per_page_count
              })}>показати ще 20</div>
            </Show>
          </LoaderWrapper>
        </div>
    )
  }

}

export {Notifications_list}
