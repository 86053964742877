import * as React from 'react';
import * as PropTypes from 'prop-types'
import classNames from 'classnames';

export class Loader extends React.Component {
  static propTypes = {
    is_active: PropTypes.bool,
    fullScreen: PropTypes.bool,
  };

  render() {

    const {is_active, fullScreen} = this.props;

    return is_active ? (
        <div className={classNames('lds-ring', {
          full_screen: fullScreen
        })}>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
    ) : null
  }
}
