import * as React from 'react';

export const WithdrawalTabIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25.999" height="27.001"
         viewBox="0 0 25.999 27.001">
      <defs>
        <style>{'.withdrawal-a{fill:none;}.withdrawal-b{fill:#437c90;}.withdrawal-c{filter:url(#withdrawal-a);}'}</style>
        <filter id="withdrawal-a" x="0" y="1.001" width="25.999" height="26" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1" result="b"/>
          <feFlood floodColor="#001d27" floodOpacity="0.137"/>
          <feComposite operator="in" in2="b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g transform="translate(0.999)">
        <path className="withdrawal-a" d="M0,0H24V24H0Z"/>
        <g className="withdrawal-c" transform="matrix(1, 0, 0, 1, -1, 0)">
          <path className="withdrawal-b"
                d="M6797,12335a2,2,0,0,1,2-2h16a2,2,0,0,1,2,2v12a2.006,2.006,0,0,1-2,2h-14l-4,4Zm2,13.17,1.169-1.172H6815v-12h-16Zm7-2.171v-1h-2v-2h4v-1h-3a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1h1v-1h2v1h2v2h-4v1h3a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1h-1v1Z"
                transform="translate(-6794 -12331)"/>
        </g>
        <rect className="withdrawal-b" width="2" height="2" transform="translate(11 12)"/>
      </g>
    </svg>
);
