import * as React from 'react';

export const LeaderTabIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="25"
         viewBox="0 0 24 25">
      <defs>
        <style>{'.leader_a{fill:#437c90;}.leader_b{fill:none;}.leader_c{filter:url(#leader_c);}.leader_d{filter:url(#leader_a);}'}</style>
        <filter id="leader_a" x="1" y="5.019" width="22.001" height="19.981" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1" result="leader_b"/>
          <feFlood flood-color="#001d27" flood-opacity="0.137"/>
          <feComposite operator="in" in2="leader_b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
        <filter id="leader_c" x="5" y="0" width="14.03" height="10.019" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1" result="d"/>
          <feFlood flood-color="#001d27" flood-opacity="0.137"/>
          <feComposite operator="in" in2="d"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g className="leader_d" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path className="leader_a"
              d="M10005,7461h-16v-2c0-2.626,5.209-4,8-4s8,1.374,8,4v2Zm-8-8a4,4,0,0,1-3.477-5.981h6.952A4,4,0,0,1,9997,7453Z"
              transform="translate(-9985 -7441)"/>
      </g>
      <path className="leader_b" d="M0,0H24V24H0Z"/>
      <g className="leader_c" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path className="leader_a" d="M5.772,8.259,4.015,6,2.259,8.259,0,6l.5,4.019H7.529L8.03,6Z"
              transform="translate(8 -5)"/>
      </g>
    </svg>
)
