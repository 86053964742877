import {
  Api_call,
  Catch,
  Load
} from "../helpers";
import {
  API_ROUTES, LOADER_TYPES, ROUTES
} from '../constants';
import {
  User_store
} from '../stores'
import axios from 'axios';
import {history} from "../helpers/history";

export class User_service {
  @Api_call
  static async sign_in_api_call(payload = {}) {
    return axios.post(API_ROUTES.SIGN_IN, payload)
  }

  @Api_call
  static async get_profile_api_call() {
    return axios.get(API_ROUTES.PROFILE)
  }

  @Catch
  static async authenticate_user(payload = {}) {
    const {
      data,
      success
    } = await this.sign_in_api_call({
      ...payload,
      device_id: 'admin_panel'
    });

    if (success) User_store.log_in(data);

    return success;

  }

  @Catch
  @Load(LOADER_TYPES.APP_INITIALIZE)
  static async load_profile() {
    if (!User_store.is_logged_in) return history.push(ROUTES.SIGN_IN);

    const {
      data,
      success
    } = await this.get_profile_api_call();

    if (success) User_store.update_profile(data);

    return success;

  }
}
