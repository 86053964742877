import * as React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';

import {ROUTES} from '../constants';
import {User_store} from "../stores";

import {Withdrawal_records} from "../stories/withdraw/containers";
import {Notifications_list, Create_notification} from '../stories/notifications/containers';
import {Franchise_records} from '../stories/franchise'
import {Sidebar} from "../stories/sidebar/containers";
import {Bills_records} from '../stories/bills';
import {Leaders_records, Leader_info_records} from '../stories/leaders';

@observer
class Private extends React.Component {
  render() {

    if (!User_store.is_logged_in) return null;

    return (
        <React.Fragment>
          <Sidebar/>
          <div className="content">
            <Switch>
              <Route path={ROUTES.WITHDRAWAL} component={Withdrawal_records}/>
              <Route exact path={ROUTES.NOTIFICATIONS} component={Notifications_list}/>
              <Route exact path={ROUTES.CREATE_NOTIFICATION} component={Create_notification}/>
              <Route exact path={ROUTES.FRANCHISE} component={Franchise_records}/>
              <Route exact path={ROUTES.BILLS} component={Bills_records}/>
              <Route exact path={ROUTES.LEADERS} component={Leaders_records}/>
              <Route path={ROUTES.LEADER_INFO} component={Leader_info_records}/>
              <Redirect to={ROUTES.BILLS}/>
            </Switch>
          </div>
        </React.Fragment>
    )
  }
}

export {Private};
