import {Api_call, Catch} from "../helpers";
import axios from "axios";
import {API_ROUTES} from "../constants";
import {toast} from "react-toastify";


export class Content_service {
  @Api_call
  static async single_file_upload_api_call(file) {

    const form_data = new FormData();

    form_data.append('file', file);

    return axios.post(API_ROUTES.UPLOAD_FILE, form_data)
  }

  @Catch
  static async upload_files(files) {
    return Promise.all(
        files.map(
            async file => {
              const result = await this.single_file_upload_api_call(file);
              if (!result.success) toast.error(`Не вдалося завантажити файл ${file.name}`);
              else return result.data;
            }
        )
    ).then(result => result.filter(Boolean));
  }

}
