export const ROUTES = {
  SIGN_IN: '/sign_in',
  WITHDRAWAL: '/withdrawal',
  NOTIFICATIONS: '/notifications',
  CREATE_NOTIFICATION: '/notifications/create_notification',
  FRANCHISE : '/franchise',
  BILLS : '/bills',
  LEADERS : '/leaders',
  LEADER_INFO : '/leaders/:id'
};
