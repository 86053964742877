import * as React from 'react';
import {observer} from 'mobx-react';
import {create_notification_form} from '../forms'

import {history} from "../../../helpers/history";
import {ROUTES} from "../../../constants";
import {ALLOWED_IMG_TYPES} from '../constants';

import {Panel} from "../../../components/section_panel";
import {Input} from "../../../components/fields/input";
import {Checkbox} from "../../../components/fields/checkbox";
import {TextArea} from "../../../components/fields/area";
import {Content_service} from "../../../services";
import {Attachments_panel, FileUploader} from '../components';
import {Show} from '../../../components/condition'
import {Loader} from "../../../components/loader";
import {Notifications_service} from '../../../services'

import {toast} from "react-toastify";

@observer
class Create_notification extends React.Component {

  state = {
    uploading: false,
    images: []
  };

  componentDidMount() {
    create_notification_form.clear();
  }

  submit_notification_record = (...args) => {
    const attachment_ids = this.state.images.map(image => image.id);

    if (attachment_ids.length) create_notification_form.$('attachment_ids').set(attachment_ids);

    create_notification_form.onSubmit(...args);
  };

  delete_attachment = id => () => this.setState({
    images: this.state.images.filter(image => image.id !== id)
  });

  handle_file_upload = async e => {
    const files = Array.from(e.target.files);

    if ((this.state.images.length + files.length) > 10) toast.error('Ви не можете додати більше 10 файлів');

    if (files.find(({type}) => type.split('/')[0] !== ALLOWED_IMG_TYPES)) toast.error('Ви можете додати тільки зоюраження');

    this.setState({uploading: true});

    const files_to_set = await Content_service.upload_files(files);

    this.setState({
      uploading: false,
      images: this.state.images.concat(files_to_set)
    })
  };

  render() {
    const {uploading, images} = this.state;

    return (
        <div className="create_notification_tab">
          <Panel title={'Створити сповіщення'}>
            <div className="action_buttons">
              <div className="discard" onClick={() => history.push(ROUTES.NOTIFICATIONS)}>відмінити</div>
              <div className="save" onClick={this.submit_notification_record}>зберегти
              </div>
            </div>
          </Panel>
          <form className="form_wrap">
            <div className="group_select_wrap">
              <Checkbox field={create_notification_form.$('is_plain_user')}/>
              <Checkbox field={create_notification_form.$('is_franchise')}/>
              <Checkbox field={create_notification_form.$('is_franchise_leader')}/>
            </div>
            <Input field={create_notification_form.$('title')}/>
            <TextArea field={create_notification_form.$('body')}/>
            <Show condition={!uploading} defaults={<Loader is_active={true}/>}>
              <React.Fragment>
                <FileUploader handleUpload={this.handle_file_upload} disabled={images.length >= 10}/>
                <Attachments_panel should_delete attachments={images} delete_attachment={this.delete_attachment}/>
              </React.Fragment>
            </Show>
          </form>
        </div>
    )
  }

}

export {Create_notification}
