import * as React from 'react';

class Header extends React.PureComponent {

  render() {

    return (
        <div className="record_wrap head">
          <div/>
          <div className="literal_info">Телефон</div>
          <div className="literal_info">Email</div>
          <div className="literal_info centered" title='Реєстрації'>Реєстрації</div>
          <div className="literal_info centered" title='Реєстрації + оплата'>Реєстрації + оплата</div>
          <div className="literal_info centered" title='Всього доходу'>Всього доходу</div>
          <div className="literal_info right" title='Всього виведено'>Всього виведено</div>
        </div>
    )
  }
}

export {
  Header
};
