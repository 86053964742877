import * as React from 'react';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';
import {history} from "../../../helpers/history";
import {ROUTES} from "../../../constants";
import  classNames from 'classnames'

@observer
class Record extends React.Component {

  static propTypes = {
    record: PropTypes.object,
    ignoring_pointer_events: PropTypes.bool
  };

  render() {

    const {
      record: {
        id,
        first_name,
        last_name,
        avatar,
        telephone,
        email,
        invited_count,
        franchise_count,
        bonuses_count,
        withdraw_count
      },
      ignoring_pointer_events
    } = this.props;

    const full_name = `${first_name} ${last_name}`;

    return (
        <div onClick={() => history.push(ROUTES.LEADERS + '/' + id)} className={classNames("record_wrap", "sub_on_hover", {ignoring_pointer_events})}>
          <div className="user_info">
            <div className="avatar" style={{
              backgroundImage: `url("${avatar?.source_url}")`
            }}/>
            <div className="full_name" title={full_name}>{full_name}</div>
          </div>
          <div className="literal_info" title={telephone.telephone}>{telephone.telephone}</div>
          <div className="literal_info" title={email.email}>{email.email}</div>
          <div className="literal_info centered">{invited_count}</div>
          <div className="literal_info centered">{franchise_count}</div>
          <div className="literal_info centered">{bonuses_count} $</div>
          <div className="value right">{withdraw_count} $</div>
        </div>
    )

  }
}

export {
  Record
};
