import * as React from 'react';
import {observer} from 'mobx-react';

import {Leaders_service} from '../../../services';
import {Panel} from '../../../components/section_panel'
import {LoaderWrapper} from '../../../components/loader'

import {Leaders_store} from '../../../stores';
import {Record, Header} from '../components';
import {LOADER_TYPES} from "../../../constants";
import {Show} from "../../../components/condition";

@observer
class Leaders_records extends React.Component {

  static records_per_page_count = 20;

  constructor(props, context) {
    super(props, context);
    this.current_offset = 0;
  }

  componentDidMount() {
    this.load_page({offset: this.current_offset, limit: Leaders_records.records_per_page_count, reset: true});
  }

  componentWillUnmount() {
    Leaders_store.clear()
  }

  async load_page(payload) {
    this.current_offset = payload.offset + Leaders_records.records_per_page_count;
    return Leaders_service.get_leaders_records(payload);
  }

  renderList = () => Leaders_store.records.map(record => (
      <Record key={`record:${record.id}`} record={record}/>
  ));

  render() {
    return (
        <div className="leaders_tab">
          <Panel title={'Лідери'}/>
          <LoaderWrapper literal={LOADER_TYPES.GET_LEADERS_RECORDS_LIST}>
            <div className="list-wrap">
              <Header/>
              {this.renderList()}
            </div>
            <Show condition={!Leaders_store.list_end_reached}>
              <div className="load_more_button" onClick={() => this.load_page({
                offset: this.current_offset,
                limit: Leaders_records.records_per_page_count
              })}>показати ще 20
              </div>
            </Show>
          </LoaderWrapper>
        </div>
    )
  }

}

export {Leaders_records}
