import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {
  User_info,
  Menu_item
} from '../components';
import {
  Show
} from '../../../components/condition'
import {SIDEBAR_ITEMS} from '../constants';
import {User_store} from "../../../stores";

@withRouter
class Sidebar extends React.Component {
  renderList = () => SIDEBAR_ITEMS.map((item, i) => (
      <Menu_item
          key={i}
          {...item}
          selected={'/' + this.props.location.pathname.split('/')[1] === item.path}
      />
  ));

  render() {
    return (
        <Show condition={User_store.is_logged_in}>
          <div className="sidebar">
            <User_info/>
            <div className="sidebar_items_wrap">
              {this.renderList()}
            </div>
          </div>
        </Show>
    )
  }

}

export {
  Sidebar
}
