import * as React from 'react';
import {observer} from 'mobx-react';

import {Bills_service} from '../../../services';
import {Panel} from '../../../components/section_panel'
import {LoaderWrapper} from '../../../components/loader'

import {Bills_store} from '../../../stores';
import {Record, Header} from '../components';
import {LOADER_TYPES} from "../../../constants";
import {Show} from "../../../components/condition";
import {PERIOD_FILTERS_CONFIG} from "../config";
import classNames from 'classnames';

@observer
class Bills_records extends React.Component {

  static records_per_page_count = 20;
  current_offset = 0;

  state = {
    active_filter: 2,
    created_after: PERIOD_FILTERS_CONFIG[2].get_value()
  };

  componentDidMount() {
    this.load_page({offset: this.current_offset, limit: Bills_records.records_per_page_count, reset: true});
  }

  componentWillUnmount() {
    Bills_store.clear()
  }

  async load_page(payload) {
    this.current_offset = payload.offset + Bills_records.records_per_page_count;

    return Bills_service.get_bills_records({
      ...(this.state.created_after ? {created_after: this.state.created_after} : {}),
      ...payload,
    });
  }

  renderList = () => Bills_store.records.map(record => (
      <Record key={`record:${record.bill_id}`} record={record}/>
  ));

  render_filter_buttons = () => PERIOD_FILTERS_CONFIG.map(({title, get_value}, index) => (
      <button key={index} className={classNames("filter_button", {selected: this.state.active_filter === index})}
              onClick={
                () => {
                  const created_after = get_value();

                  this.setState({active_filter: index, created_after});

                  this.load_page({
                    offset: 0,
                    limit: Bills_records.records_per_page_count,
                    reset: true,
                    created_after
                  });
                }
              }>{title}</button>
  ));

  render() {
    return (
        <div className="bills_tab">
          <Panel title={'Кошти'}/>
          <LoaderWrapper literal={LOADER_TYPES.GET_BILLS_RECORDS_LIST}>
            <div className="filters_wrap">
              {this.render_filter_buttons()}
              <div className="total_sum">Всього за обраний період: <b>{Bills_store.total}$</b></div>
            </div>
            <div className="list-wrap">
              <Header/>
              {this.renderList()}
            </div>
            <Show condition={!Bills_store.list_end_reached}>
              <div className="load_more_button" onClick={() => this.load_page({
                offset: this.current_offset,
                limit: Bills_records.records_per_page_count
              })}>показати ще 20
              </div>
            </Show>
          </LoaderWrapper>
        </div>
    )
  }

}

export {Bills_records}
