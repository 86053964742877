import * as React from 'react';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';

import { Show } from '../../condition';
import { CheckBoxActiveIcon } from '../../../assets/icons';

@observer
class Checkbox extends React.Component {
  static propTypes = {
    field: PropTypes.object
  };

  render() {
    const {
      field,
    } = this.props;

    return (
        <div className="base-checkbox">
          <label htmlFor={field.id}>
            {field.label}

            <Show condition={field.value} defaults={<div className="checkbox-control"/>}>
              {CheckBoxActiveIcon}
            </Show>
          </label>
          <input {...field.bind({
            placeholder: field.placeholder,
            type: 'checkbox'
          })}/>
        </div>
    )
  }
}

export { Checkbox }
