import {
  Api_call,
  Catch
} from "../helpers";
import axios from "axios";
import {API_ROUTES} from "../constants";
import {Franchise_store} from "../stores";

export class Franchise_service {
  @Api_call
  static async get_franchise_records_api_call(payload) {
    return axios.get(API_ROUTES.FRANCHISE_INFO, {
      params: {
        ...payload
      }
    })
  }

  @Catch
  static async get_franchise_records({limit, offset, franchise_leader_id, reset = false}) {
    const {
      data,
      success
    } = await this.get_franchise_records_api_call(
        {limit: limit + 1, offset, franchise_leader_id}
    );

    if (success) {
      const records = data.slice(0, limit);
      reset ? Franchise_store.set_records(records) : Franchise_store.push_records(records);
      Franchise_store.set_list_end_reached_status(data.length <= limit);
    }

    return success;
  }
}
