export * from './routes';
export * from './api';
export * from './reg-exp';
export * from './loader_types';
export * from './withdrawal_records';

export const PAYMENT_TYPES = {
  CARD: 'card',
  FRANCHISE: 'franchise',
  LEADER: 'leader'
};

export const PAYMENT_TYPES_LITERALS = {
  [PAYMENT_TYPES.CARD]: 'Бізнес картка',
  [PAYMENT_TYPES.LEADER]: 'Статус Лідера',
  [PAYMENT_TYPES.FRANCHISE]: 'Статус Франчайзі'
};
