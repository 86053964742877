import {
  observable,
  action
} from 'mobx';

export const Progress_store = new class User_store {
  @observable loader_registry = {};

  is_loading = (key) => !!this.loader_registry[key];

  @action register(key) {
    this.loader_registry[key] = true;
  }

  @action unregister(key) {
    delete this.loader_registry[key];
  }
}();
