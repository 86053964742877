import * as React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import {ALLOWED_IMG_TYPES} from "../constants";

export class FileUploader extends React.Component {
  static propTypes = {
    handleUpload: PropTypes.func,
    disabled: PropTypes.bool
  };

  render() {
    const { handleUpload, disabled } = this.props;

    return (
        <div className={classNames('add_images_section', {
          disabled
        })}>
          <div className='button'>
            <label htmlFor="file-input:multi">
              Виберіть зображення
            </label>
            <input
                type='file'
                id='file-input:multi'
                accept={`${ALLOWED_IMG_TYPES}/*`}
                onChange={handleUpload}
                multiple
            />
            <div className="note">максимум 10</div>
          </div>
        </div>
    )
  }
}
