import * as React from 'react';
import {observer} from 'mobx-react';
import {User_store} from '../../../stores';

@observer
class User_info extends React.Component {
  render() {

    const full_name = `${User_store.profile.first_name} ${User_store.profile.last_name}`;
    const user_avatar_uri = User_store.profile.avatar?.source_url || '';
    const telephone = User_store.profile.telephone?.number;

    return (
        <div className="profile_wrap">
          <div className="user_avatar" style={{
            backgroundImage: `url("${user_avatar_uri}")`
          }}/>
          <div className="user_info">
            <div className="full_name" title={full_name}>{full_name}</div>
            <div className="telephone" title={telephone}>{telephone}</div>
          </div>
        </div>
    )
  }
}

export {
  User_info
}
