import * as React from 'react';
import * as PropTypes from 'prop-types';

export class Show extends React.Component {
  static propTypes = {
    condition: PropTypes.bool,
    children: PropTypes.element,
    defaults: PropTypes.element
  };

  render() {
    const {condition, children, defaults = null} = this.props;

    if (condition) {
      return children;
    }

    return defaults;
  }
}
