import {
  Api_call,
  Catch, Load,
} from "../helpers";
import {
  API_ROUTES, LOADER_TYPES,
  WITHDRAWAL_RECORDS_STATUS
} from '../constants';
import axios from 'axios';
import {Withdrawal_records_store} from "../stores";

export class Withdrawal_service {
  @Api_call
  static async get_withdraw_records_api_call({status}) {
    return axios.get(API_ROUTES.WITHDRAW, {params: {status}})
  }

  @Api_call
  static async affect_withdraw_record_api_call(payload) {
    return axios.post(API_ROUTES.AFFECT_WITHDRAWAL_RECORD, payload)
  }

  @Catch
  @Load(LOADER_TYPES.GET_WITHDRAW_RECORDS)
  static async get_withdraw_records() {
    const {
      data,
      success
    } = await this.get_withdraw_records_api_call({
      status: WITHDRAWAL_RECORDS_STATUS.PENDING
    });

    if (success) Withdrawal_records_store.set_records(data);

    return success;
  }

  @Catch
  static async affect_withdraw_record({id, action = WITHDRAWAL_RECORDS_STATUS.REJECTED}) {

    await this.affect_withdraw_record_api_call({id, action});

    return this.get_withdraw_records()
  }
}
