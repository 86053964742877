import * as React from 'react';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';

@observer
class Input extends React.Component {
  static propTypes = {
    field: PropTypes.object
  };

  render() {
    const {
      field,
    } = this.props;

    return (
        <div className="base-input">
          <input {...field.bind({
            placeholder: field.placeholder,
            type: field.type
          })}/>
          <div className="errors">
            {field.error}
          </div>
        </div>
    )
  }
}

export { Input }
