import * as React from 'react';
import {observer} from 'mobx-react';

import {Franchise_service} from '../../../services';
import {Panel} from '../../../components/section_panel'
import {LoaderWrapper} from '../../../components/loader'

import {Franchise_store} from '../../../stores';
import {Record, Header} from '../components';
import {LOADER_TYPES} from "../../../constants";
import {Show} from "../../../components/condition";
import * as PropTypes from "prop-types";

@observer
class Franchise_records extends React.Component {

  static propTypes = {
    franchise_leader_id: PropTypes.string
  };

  static records_per_page_count = 20;

  constructor(props, context) {
    super(props, context);
    this.current_offset = 0;
  }

  componentDidMount() {
    this.load_page({offset: this.current_offset, limit: Franchise_records.records_per_page_count, reset: true});
  }

  componentWillUnmount() {
    Franchise_store.clear()
  }

  async load_page(payload) {
    const {franchise_leader_id} = this.props;
    debugger;
    if (franchise_leader_id) payload.franchise_leader_id = franchise_leader_id;
    this.current_offset = payload.offset + Franchise_records.records_per_page_count;
    return Franchise_service.get_franchise_records(payload);
  }

  renderList = () => Franchise_store.records.map(record => (
      <Record key={`record:${record.id}`} record={record}/>
  ));

  render() {
    return (
        <div className="franchise_tab">
          <Panel title={'Франчайзі'}/>
          <LoaderWrapper literal={LOADER_TYPES.GET_FRANCHISE_RECORDS}>
            <div className="list-wrap">
              <Header/>
              {this.renderList()}
            </div>
            <Show condition={!Franchise_store.list_end_reached}>
              <div className="load_more_button" onClick={() => this.load_page({
                offset: this.current_offset,
                limit: Franchise_records.records_per_page_count
              })}>показати ще 20
              </div>
            </Show>
          </LoaderWrapper>
        </div>
    )
  }

}

export {Franchise_records}
