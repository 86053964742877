import * as React from 'react';

export const FranchiseTabIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="25"
         viewBox="0 0 24 25">
      <defs>
        <style>{'.franchise-a{fill:none;}.franchise-b{fill:#437c90;}.franchise-c{filter:url(#a);}'}</style>
        <filter id="a" x="1" y="3" width="22" height="22" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1" result="b"/>
          <feFlood flood-color="#001d27" flood-opacity="0.137"/>
          <feComposite operator="in" in2="b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <path className="franchise-a" d="M0,0H24V24H0Z"/>
      <g className="franchise-c" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path className="franchise-b"
              d="M12,5.9A2.1,2.1,0,1,1,9.9,8,2.1,2.1,0,0,1,12,5.9m0,9c2.97,0,6.1,1.46,6.1,2.1v1.1H5.9V17c0-.64,3.13-2.1,6.1-2.1M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"/>
      </g>
    </svg>
);
