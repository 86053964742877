import {User_store} from "../stores";
import axios from 'axios';
import {API_ROUTES, ROUTES} from "../constants";
import {history} from "./history";
import moment from "moment";
import jwt_decode from 'jwt-decode'

export const refresh_if_needed = async () => {
  try {
    const {refresh_token, access_token} = User_store;

    if (!refresh_token || !access_token) User_store.log_out();

    const now = moment.utc().toDate().getTime();

    const decoded = jwt_decode(access_token);


    const expires = decoded.exp * 1000;

    if (expires < now) {

      const {data: {data}} = await axios.post(API_ROUTES.REFRESH_TOKEN, {refresh_token});

      User_store.set_access_token(data.access_token);

      return true;
    }
  } catch (err) {

    User_store.log_out();
    history.push(ROUTES.SIGN_IN);

    return false;
  }
};
