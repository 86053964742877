import {
  observable,
  action,
  computed
} from 'mobx';
import axios from 'axios';

export const User_store = new class User_store {

  constructor() {
    this.refresh_token = localStorage.getItem('refresh_token') || '';
    this.on_access_token_changed(localStorage.getItem('access_token'))
  }

  @observable profile = {};
  @observable access_token = '';
  @observable refresh_token = '';

  @computed
  get is_logged_in() {
    return !!this.access_token;
  }

  @action log_in({access_token, refresh_token, profile} = {}) {
    this.refresh_token = refresh_token;
    this.profile = profile;

    localStorage.setItem('refresh_token', refresh_token);

    this.on_access_token_changed(access_token);
  }

  on_access_token_changed = access_token => access_token ? this.set_access_token(access_token) : this.clear_session();

  @action set_access_token(access_token) {
    this.access_token = access_token;
    localStorage.setItem('access_token', access_token);
    axios.defaults.headers.common.Authorization = 'Bearer ' + access_token;
  }

  @action clear_session() {
    this.access_token = '';
    localStorage.removeItem('access_token');
    delete axios.defaults.headers.common.Authorization;
  }

  @action log_out() {
    this.access_token = '';
    this.refresh_token = '';
    this.profile = {}
  }

  @action update_profile(data = {}) {
    this.profile = data;
  }
}();
