import * as React from 'react';

export const PlusIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <style>{'.plus-a{fill:none;}.plus-b{fill:#f5f5f5;}'}</style>
      </defs>
      <path className="plus-a" d="M0,0H24V24H0Z" transform="translate(0)"/>
      <path className="plus-b" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" transform="translate(0)"/>
    </svg>
);
