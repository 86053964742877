import * as React from 'react';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';

@observer
class Record extends React.Component {

  static propTypes = {
    record: PropTypes.object
  };

  render() {

    const {
      record: {
        first_name,
        last_name,
        avatar,
        telephone,
        email,
        invited_count,
        cards_count,
        bonuses_count,
        withdraw_count
      }
    } = this.props;

    const full_name = `${first_name} ${last_name}`;

    return (
        <div className="record_wrap">
          <div className="user_info">
            <div className="avatar" style={{
              backgroundImage: `url("${avatar?.source_url}")`
            }}/>
            <div className="full_name" title={full_name}>{full_name}</div>
          </div>
          <div className="literal_info" title={telephone.telephone}>{telephone.telephone}</div>
          <div className="literal_info" title={email.email}>{email.email}</div>
          <div className="literal_info centered">{invited_count}</div>
          <div className="literal_info centered">{cards_count}</div>
          <div className="literal_info centered">{bonuses_count} $</div>
          <div className="value right">{withdraw_count} $</div>
        </div>
    )

  }
}

export {
  Record
};
