const BASE_API = "https://dev.api.cobook.app/api/v1";
const BASE_CM = "https://dev.api.cobook.app/cm/v1";

export const API_ROUTES = {
  SIGN_IN: `${BASE_API}/sign_in`,
  PROFILE: `${BASE_API}/profile`,
  REFRESH_TOKEN: `${BASE_API}/refresh`,
  WITHDRAW: `${BASE_API}/bonuses/withdraw/global_list`,
  NOTIFICATIONS: `${BASE_API}/news`,
  RESEND_NOTIFICATION: `${BASE_API}/news/resend`,
  AFFECT_WITHDRAWAL_RECORD: `${BASE_API}/bonuses/withdraw/affect`,
  FRANCHISE_INFO: `${BASE_API}/users/franchise`,
  LEADER_INFO: `${BASE_API}/users/leader`,
  BILLS_LIST: `${BASE_API}/bonuses/bills/list`,
  UPLOAD_FILE: `${BASE_CM}/upload/single`,
};
