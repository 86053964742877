import {
  observable,
  action
} from 'mobx';

export const Withdrawal_records_store = new class Withdrawal_records_store {
  @observable records = [];

  @action set_records(records = []) {
    this.records = records
  }

  @action add_record(record = {}) {
    this.records.unshift(record);
  }

  @action remove_record(target_id = null) {
    this.set_records(
        this.records.filter(({id} = {}) => id !== target_id)
    )
  }
}();
