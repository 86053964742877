import * as React from 'react';
import * as PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

export class Menu_item extends React.PureComponent {
  static propTypes = {
    path: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.element,
    selected: PropTypes.bool
  };

  render() {
    const {path, title, icon, selected} = this.props;

    return (
        <Link to={path} className={classNames('sidebar_item', {
          selected
        })}>
          {icon}
          <span className="text">
            {title}
          </span>
        </Link>
    )
  }
}
