import {refresh_if_needed} from '../helpers/refresh'
import {Progress_store, User_store} from "../stores";
import {history} from "./history";
import {ROUTES} from "../constants";
import {toast} from 'react-toastify'

export function Api_call(target, name, descriptor) {
  const original = descriptor.value;

  descriptor.value = async function (...args) {
    try {

      await refresh_if_needed();

      const {data: {data} = {}} = await original.apply(this, args);

      return {
        data,
        success: true
      }
    } catch (err) {
      switch (err.response.status) {
        case 401:
          User_store.log_out();
          history.push(ROUTES.SIGN_IN);
          break;
        default:
          break;
      }

      if (err.response) toast.error(err.response.data.error_localized_message);
      return {
        success: false
      }
    }
  };

  return descriptor

}

export function Catch(target, name, descriptor) {
  const original = descriptor.value;

  descriptor.value = async function (...args) {
    try {
      return await original.apply(this, args);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return descriptor

}

export const Load = (key) => (target, name, descriptor) => {
  const original = descriptor.value;

  descriptor.value = async function (...args) {
    if (!Progress_store.is_loading(key)) Progress_store.register(key);
    const result = await original.apply(this, args);

    if (Progress_store.is_loading(key)) Progress_store.unregister(key);

    return result;
  };

  return descriptor

};
