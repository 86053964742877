import {
  Api_call,
  Catch
} from "../helpers";
import axios from "axios";
import {API_ROUTES} from "../constants";
import {Bills_store} from "../stores";

export class Bills_service {
  @Api_call
  static async get_bills_records_api_call(payload) {
    return axios.post(API_ROUTES.BILLS_LIST, payload)
  }

  @Catch
  static async get_bills_records({limit, offset, reset = false, ...rest}) {
    const {
      data,
      success
    } = await this.get_bills_records_api_call(
        {limit: limit + 1, offset, ...rest}
    );

    if (success) {

      const {
        records,
        total
      } = data;

      const obtained_records = records.slice(0, limit);
      reset ? Bills_store.set_records(obtained_records) : Bills_store.push_records(obtained_records);
      Bills_store.set_total(total);
      Bills_store.set_list_end_reached_status(records.length <= limit);
    }

    return success;
  }
}
