import {Api_call, Catch} from "../helpers";
import axios from "axios";
import {API_ROUTES, ROUTES} from "../constants";
import {Notification_records_store} from "../stores/notification_records";
import {history} from '../helpers/history'
import {toast} from "react-toastify";

export class Notifications_service {
  @Api_call
  static async get_notification_api_call(payload) {
    return axios.get(API_ROUTES.NOTIFICATIONS, {
      params: {
        ...payload
      }
    })
  }

  @Api_call
  static async delete_notification_api_call(payload) {
    return axios.delete(API_ROUTES.NOTIFICATIONS, {
      params: {
        ...payload
      }
    })
  }

  @Api_call
  static async create_notification_api_call(payload) {
    return axios.post(API_ROUTES.NOTIFICATIONS, payload)
  }

  @Api_call
  static async resend_notification_api_call(payload) {
    return axios.post(API_ROUTES.RESEND_NOTIFICATION, payload)
  }

  static async resend_notification(payload) {
    const {success} = await this.resend_notification_api_call(payload);
    if (success) toast.success('Новина успішно переслана!');
    Notification_records_store.set_records(
        Notification_records_store.records.sort((x, y) => x.id === payload.id ? -1 : y.id === payload.id ? 1 : 0)
    )
  }

  @Catch
  static async create_notification(payload) {
    const {success} = await this.create_notification_api_call(payload);

    if (success) history.push(ROUTES.NOTIFICATIONS);

    return success;
  }

  @Catch
  static async get_notifications_list({limit, offset, reset = false}) {
    const {
      data,
      success
    } = await this.get_notification_api_call(
        {limit: limit + 1, offset}
    );

    if (success) {
      const records = data.slice(0, limit);
      reset ? Notification_records_store.set_records(records) : Notification_records_store.push_records(records);
      Notification_records_store.set_list_end_reached_status(data.length <= limit);
    }

    return success;
  }

  @Catch
  static async init_list({limit, offset}) {

  }

  @Catch
  static async delete_notification({id}) {
    const {
      data,
      success
    } = await this.delete_notification_api_call({id});

    if (success) Notification_records_store.remove_record(id);
  }

}
