import * as React from 'react';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';
import {Withdrawal_service} from "../../../services";
import {WITHDRAWAL_RECORDS_STATUS} from "../../../constants";

@observer
class Record extends React.Component {

  static propTypes = {
    record: PropTypes.object
  };

  render() {

    const {
      record: {
        id,
        value,
        created_by,
        contact_telephone,
        contact_email
      }
    } = this.props;

    const full_name = `${created_by.first_name} ${created_by.last_name}`;
    const user_avatar_uri = created_by?.avatar?.source_url || null;
    const email_address = contact_email.address;
    const telephone_number = contact_telephone.number;

    return (
        <div className="record_wrap">
          <div className="user_info">
            <div className="avatar" style={{
              backgroundImage: `url("${user_avatar_uri}")`
            }}/>
            <div className="full_name" title={full_name}>{full_name}</div>
          </div>
          <div className="literal_info" title={email_address}>{email_address}</div>
          <div className="literal_info" title={telephone_number}>{telephone_number}</div>
          <div className="value">{value} $</div>
          <button
              className="reject_button"
              onClick={()=> Withdrawal_service.affect_withdraw_record({id, action : WITHDRAWAL_RECORDS_STATUS.REJECTED})}>
            Відхилити
          </button>
          <button
              className="approve_button"
              onClick={()=> Withdrawal_service.affect_withdraw_record({id, action : WITHDRAWAL_RECORDS_STATUS.APPROVED})}>
            Підтвердити
          </button>
        </div>
    )
  }
}

export {
  Record
};
