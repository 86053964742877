import * as React from 'react';

export const NotificationTabIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="27.5"
         viewBox="0 0 24 27.5">
      <defs>
        <style>{'.notification-a{fill:none;}.notification-b{fill:#437c90;}.notification-c{filter:url(#notification-a);}'}</style>
        <filter id="notification-a" x="0" y="0" width="24" height="27.5" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="1" result="b"/>
          <feFlood flood-color="#001d27" flood-opacity="0.137"/>
          <feComposite operator="in" in2="b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <path className="notification-a" d="M0,0H24V24H0Z"/>
      <g className="notification-c" transform="matrix(1, 0, 0, 1, 0, 0)">
        <path className="notification-b"
              d="M10.01,21.01a1.99,1.99,0,0,0,3.98,0ZM12,6a5,5,0,0,1,5,5v7H7V11A5,5,0,0,1,12,6Zm0-4.5A1.5,1.5,0,0,0,10.5,3V4.17A6.993,6.993,0,0,0,5,11v6L3,19v1H21V19l-2-2V11a6.993,6.993,0,0,0-5.5-6.83V3A1.5,1.5,0,0,0,12,1.5ZM13,16h0Z"
              transform="translate(0 -0.5)"/>
      </g>
    </svg>
);
