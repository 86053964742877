import * as React from 'react';
import {Route, Redirect} from 'react-router-dom';
import * as PropTypes from 'prop-types';

import {User_store} from '../../stores';
import {ROUTES} from "../../constants";
import {observer} from "mobx-react";

@observer
class NonAuthRoute extends React.Component {
  static propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.func
  };

  render() {
    const { exect, path, component } = this.props;

    if (!User_store.is_logged_in) {
      return (
          <Route
              exect={exect}
              path={path}
              component={component}
          />
      )
    }

    return (
        <Redirect to={ROUTES.WITHDRAWAL}/>
    )
  }
}

export {NonAuthRoute}
