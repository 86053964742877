import Form from 'mobx-react-form';
import vjf from 'mobx-react-form/lib/validators/VJF';
import {is_required} from '../../../helpers/validators';
import {toast} from "react-toastify";
import {Notifications_service} from "../../../services";

const plugins = {
  vjf: vjf()
};

const fields = [
  {
    id: 'is_plain_user',
    value: true,
    name: 'is_plain_user',
    label: 'Прості Користувачі',
  },
  {
    id: 'is_franchise',
    value: true,
    name: 'is_franchise',
    label: 'Франчайзі',
  },
  {
    id: 'is_franchise_leader',
    value: true,
    name: 'is_franchise_leader',
    label: 'Лідер',
  },
  {
    name: 'title',
    placeholder: 'Назва сповіщення',
    validators: [is_required]
  },
  {
    name: 'body',
    placeholder: 'Опис',
    validators: [is_required]
  },
  {
    name: 'attachment_ids',
    value: []
  }
];

const hooks = {
  onSuccess(form) {
    const {
      is_plain_user,
      is_franchise,
      is_franchise_leader,
      title,
      body,
      attachment_ids
    } = form.values();

    if (!title || !body) return toast.error('Необхідно заповнити обов\'язкові поля');
    if (!(is_franchise || is_franchise_leader || is_plain_user)) return toast.error('Необхідно обрати принайні одну групу користувачів');

    Notifications_service.create_notification({
      is_plain_user,
      is_franchise,
      is_franchise_leader,
      title,
      body,
      attachment_ids,
    });
  },
};

export const create_notification_form = new Form({fields}, {plugins, hooks});
