import * as React from 'react';

export const CheckBoxActiveIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <style>{'.checkbox_active-a{fill:none;}.checkbox_active-b{fill:#2cb6c5;}'}</style>
      </defs>
      <path className="checkbox_active-a" d="M0,0H24V24H0Z"/>
      <path className="checkbox_active-b"
            d="M19,3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/>
    </svg>
);
