import * as React from 'react';
import {observer} from 'mobx-react';
import * as PropTypes from 'prop-types';
import {Attachments_panel} from './Attachments-panel';
import moment from 'moment';
import {Notifications_service} from "../../../services";
import {Show} from '../../../components/condition'

import {Trash, Resend} from '../../../assets/icons';

@observer
class Record extends React.Component {

  static propTypes = {
    id: PropTypes.number,
    record: PropTypes.object
  };

  render() {

    const {
      id,
      record: {
        title,
        body,
        created_at,
        attachments
      }
    } = this.props;

    return (
        <div className="record_wrap">
          <div className="content_wrap">
            <div className="title">{title}</div>
            <div className="description">{body}</div>
            <Show condition={attachments && attachments.length > 0}>
              <Attachments_panel attachments={attachments}/>
            </Show>
          </div>
          <div className="utilities_wrap">
            <div
                className="date-time">{`${moment(created_at).format("LT")} / ${moment(created_at).format('DD-MM-YYYY')}`}</div>
            <div className="actions_wrap">
              <div className="resend_section" onClick={() => Notifications_service.resend_notification({id})}>
                {Resend}
              </div>
              <div className="delete_section"
                   onClick={() => Notifications_service.delete_notification({id})}>
                {Trash}
              </div>
            </div>
          </div>
        </div>
    )

  }
}

export {
  Record
};
