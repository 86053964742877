import {ROUTES} from '../../../constants';
import {
  FranchiseTabIcon,
  NotificationTabIcon,
  WithdrawalTabIcon,
  Bills_tab_icon,
  LeaderTabIcon
} from '../../../assets/icons';

export const SIDEBAR_ITEMS = [
  {
    path: ROUTES.BILLS,
    title: 'Кошти',
    icon: Bills_tab_icon
  },
  {
    path: ROUTES.FRANCHISE,
    title: 'Франчазі',
    icon: FranchiseTabIcon
  },
  {
    path: ROUTES.LEADERS,
    title: 'Лідери',
    icon: LeaderTabIcon
  },
  {
    path: ROUTES.WITHDRAWAL,
    title: 'Запити На Вивід',
    icon: WithdrawalTabIcon
  },
  {
    path: ROUTES.NOTIFICATIONS,
    title: 'Сповіщення',
    icon: NotificationTabIcon
  }
];
