import * as React from 'react';
import { ToastContainer } from 'react-toastify';

export class Toast extends React.Component {
  render() {
    return (
        <ToastContainer
            pauseOnFocusLoss
            pauseOnHover
            draggable
            autoClose={5000}
        />
    )
  }
}
